// extracted by mini-css-extract-plugin
export var basisHalf = "CareerPositions-module--basisHalf--f5f6a";
export var body = "CareerPositions-module--body--a58f7";
export var container = "CareerPositions-module--container--1bf5f";
export var dateTime = "CareerPositions-module--dateTime--d71bb";
export var footer = "CareerPositions-module--footer--544cd";
export var header = "CareerPositions-module--header--adaa0";
export var item = "CareerPositions-module--item--48bee";
export var label = "CareerPositions-module--label--b28bf";
export var link = "CareerPositions-module--link--6fa44";
export var list = "CareerPositions-module--list--6c1f6";
export var requirement = "CareerPositions-module--requirement--2d695";
export var requirements = "CareerPositions-module--requirements--51fa1";
export var salary = "CareerPositions-module--salary--56f69";
export var salaryContainer = "CareerPositions-module--salaryContainer--e04e2";
export var time = "CareerPositions-module--time--acde9";
export var title = "CareerPositions-module--title--145a9";
export var type = "CareerPositions-module--type--37e66";