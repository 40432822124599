import { Link } from "gatsby";
import * as React from "react";
import { nanoid } from "nanoid";
import LinkArrow from "./Icons/LinkArrow";
import { getLabel } from "../contentful/data-provider";
import * as styles from "./CareerPositions.module.scss";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const CareerPositions = ({ elements, labels }) => {
	const renderItems = () => {
		return elements.map((item) => {
			return (
				<li className={styles.item} key={nanoid()}>
					<Link to={item.node_locale === 'de' ? `/karriere/${item.slug}` : `/en/career/${item.slug}`}
						  className={styles.link}
					>
						<div className={styles.header}>
							<div className={styles.basisHalf}>
								<h2 className={styles.title}>{item.title}</h2>
							</div>
							<div className={styles.basisHalf}>
								<div className={styles.salaryContainer}>
									<div className={styles.salary}>{item.salary}</div>
									<div className={styles.type}>{item.type}</div>
								</div>
							</div>
						</div>
						<div className={styles.body}>
							{item.excerpt && renderRichText(item.excerpt)}
						</div>
						<div className={styles.footer}>
							<div className={styles.dateTime}>
								<div className={styles.date}>{item.date}</div>
							</div>
							<div className={styles.label}>
								{getLabel("view-details-label", labels)}
								<LinkArrow size={"small"} />
							</div>
						</div>
					</Link>
				</li>
			);
		});
	};

	return (
		<div className={styles.container}>
			<ul className={styles.list}>{renderItems()}</ul>
		</div>
	);
};

export default CareerPositions;
