import * as React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/Section';
import PageTitle from '../components/PageTitle';
import PageHeader from '../components/PageHeader';
import {getImage} from "../contentful/data-provider";
import CareerContent from '../components/CareerContent';
import FullWidthImage from '../components/FullWidthImage';
import CareerPositions from '../components/CareerPositions';
import {Parallax} from "react-scroll-parallax";
import useWindowSize from "../hooks/window-size";

const CareerPage = ({data}) => {
    const {contentfulBasicPageSection, contentfulCareerSection, allContentfulLabel} = data;
    const windowSize = useWindowSize();
    const paralaxValue = 20;
    const responsiveValue = windowSize.width >= 769 ? paralaxValue : 0;

    return (
        <Layout>
            <main>
                <PageHeader hasNoBottomMargin>
                    <PageTitle>
                        <h1>{contentfulBasicPageSection.title}</h1>
                    </PageTitle>
                </PageHeader>
                <Section hasMaxWidth isCareerContent>
                    <CareerContent>
                        <CareerPositions elements={contentfulCareerSection.elements} labels={allContentfulLabel.nodes}/>
                    </CareerContent>
                </Section>
                <Section isMobileHidden>
                    {contentfulBasicPageSection.images.length >= 1 &&
                        <Parallax translateY={[0, responsiveValue]}>
                            <FullWidthImage src={getImage(contentfulBasicPageSection.images[0]).url}/>
                        </Parallax>
                    }
                </Section>
            </main>
        </Layout>
    )
}

export default CareerPage;

export function Head({data}) {
    const {contentfulBasicPageSection} = data;
    return (
        <title>{contentfulBasicPageSection.title} | Derschlag</title>
    )
}

export const query = graphql`
    query ($locale: String!) {
        contentfulBasicPageSection(contentful_id: {eq: "34rQE6oDla11nOwN5kX51q"}, node_locale: {eq: $locale}) {
            ... BasicPageFields
        }

        contentfulCareerSection(contentful_id: {eq: "agqBlWvS2XUbhW2bT93qk"}, node_locale: {eq: $locale}) {
            elements {
                ... CareerFields
            }
        }
        allContentfulLabel (filter: {node_locale: {eq: $locale}}) {
            nodes {
                ... LabelFields
            }
        }
    }
`;
